.page-home {
  background: $nearBlack;
  color: $offWhite;
  padding: 50px 0;

  @media (min-width: $desktop) {
    min-height: 90vh;
  }

  .header-text {
    @extend .ultra;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    margin-top: 15vh;
  }

  .img-container {
    margin: 25px auto;
    width: fit-content;

    .logo-img {
      margin: 25px auto;
      height: 100px;
    }

    .dl-img {
      height: 55px;
    }
  }
}
