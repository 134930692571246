$red: #FF0000;
$yellow: #F0FF46;
$blue: #10ADFF;
$black: #000000;
$accentBlue: #55C5FF;
$nearBlack: #171717;
$offWhite: #ECF0F1;
$green: #2ECC71;

$sm-phones: 600px;
$lg-phones: 768px;
$desktop: 992px;

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

.green {
  color: $green;
}

.blue {
  color: $blue;
}

@font-face {
  font-family: 'GothamUltra';
  src: url('../fonts/GothamUltra.otf');
}

@font-face {
  font-family: 'GothamBook';
  src: url('../fonts/GothamBook.otf');
}

@font-face {
  font-family: 'GothamMedium';
  src: url('../fonts/GothamMedium.otf');
}

.ultra {
  font-family: 'GothamUltra', sans-serif;
}

.book {
  font-family: 'GothamBook', sans-serif;
}

.medium {
  font-family: 'GothamMedium', sans-serif;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.select {
  -webkit-touch-callout: default; /* iOS Safari */
  -webkit-user-select: all; /* Safari */
  -moz-user-select: all; /* Old versions of Firefox */
  -ms-user-select: all; /* Internet Explorer/Edge */
  user-select: all; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

// remove default blue border on collapsible elements
:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}
