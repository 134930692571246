body {
  @extend .noselect;
  font-family: 'GothamMedium', sans-serif;
}

img {
  @extend .select;
}

.page {
  text-align: center;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.terms, .privacy {
  text-align: left;
  background-color: $nearBlack;
  color: white;
  padding: 20px;
  max-width: 800px;
  margin: auto;

  p {
    font-size: 13pt;
    line-height: 22pt;
    margin-bottom: 1.2rem;
  }
}
